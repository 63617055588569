import React from 'react';
import { Typography, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmailSubscription = ({ booking, onCheckboxChange }) => {
    const { t } = useTranslation();

    return (
        <Stack sx={{ alignItems: 'center' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={booking.subscribed}
                        onChange={onCheckboxChange}
                        name="subscribed"
                        inputProps={{ autoComplete: 'off' }}
                    />
                }
                label={<Typography>{t('booking.acceptSubscription')}</Typography>}
            />
        </Stack>
    );
};

export { EmailSubscription };
