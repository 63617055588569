import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';

const CountDown = ({ time, onFinish }) => {
    const [seconds, setSeconds] = useState(time);
    const theme = useTheme();

    let timer = useRef();

    useEffect(() => {
        timer.current = setInterval(() => setSeconds((prev) => prev - 1), 1000);

        return () => clearInterval(timer.current);
    }, []);

    if (seconds <= 0) {
        clearInterval(timer.current);
        onFinish();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '16px',
                    fontWeight: 300,
                }}
            >
                {`${seconds}s`}
            </Box>
            <CircularProgress variant="determinate" thickness={4} size={100} value={100 - (100 / time) * seconds} />
        </Box>
    );
};

export { CountDown };
