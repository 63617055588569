import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import { Tooltip, Typography } from '@mui/material';
import css from './BookingDetailsForm.module.css';
import { ExpansionPanelSummary } from '../../../common/components/ExpansionPanelSummary';
import { extraPrice } from './extraPrice';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DISABLED_SERVICES } from '../../../constants';

const ChildSeats = ({ childSeats, transfer, updateTransfer }) => {
    const { t } = useTranslation();
    const firstRef = useRef(null);
    const [isExpanded, setExpanded] = useState(false);

    useEffect(() => {
        if (!isExpanded) {
            return;
        }

        // TODO: Not working atm, focus should be on first (not disabled) Input
        const btn = firstRef.current.querySelector('input');

        if (btn) {
            btn.focus();
        }
    }, [isExpanded]);

    return (
        <Accordion onChange={(_, expanded) => setExpanded(expanded)}>
            <ExpansionPanelSummary text="booking.addChildSeats" />
            <div className={css.expansionBody}>
                <div className={css.grid} ref={firstRef}>
                    {childSeats.map((childSeat, childSeatKey) => (
                        <div key={childSeatKey} className={css.gridCell}>
                            <Select
                                value={transfer.extras[childSeat.internalCode] || 0}
                                name={`extras.${childSeat.internalCode}`}
                                onChange={updateTransfer}
                                disabled={!!DISABLED_SERVICES[childSeat.internalCode]}
                            >
                                {[...Array(10)].map((_, key) => (
                                    <MenuItem value={key} key={key}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Typography sx={{ position: 'relative', ml: 1 }}>
                                {t(`extras.${childSeat.internalCode}`)}&nbsp;{extraPrice(childSeat.price)}
                                {!!DISABLED_SERVICES[childSeat.internalCode] && (
                                    <Tooltip
                                        title={t(DISABLED_SERVICES[childSeat.internalCode])}
                                        placement="right"
                                        enterTouchDelay={0}
                                        leaveTouchDelay={3000}
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                position: 'absolute',
                                                p: 0.5,
                                                top: '-10px',
                                                right: '-22px',
                                                fontSize: '16px',
                                                color: 'text.secondary',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </Accordion>
    );
};

export { ChildSeats };
