import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { PageTitle } from '../../common/components/PageTitle';
import { CountDown } from '../../common/components/CountDown/CountDown';

const BookingSuccess = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const goToPaymentPage = () => {
        if (location.state) {
            window.location.href = location.state.paymentLink;
        }
    };

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t('booking.success.title')}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <Box sx={{ pt: 0.5, pb: 1, maxWidth: '900px', textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        {t('booking.success.title2')}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        {t('booking.success.item')}
                    </Typography>
                    <CountDown time={20} onFinish={goToPaymentPage} />
                    <Button variant="contained" color="secondary" size="large" sx={{ mt: 4 }} onClick={goToPaymentPage}>
                        {t('booking.success.goToPaymentPage')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export { BookingSuccess };
