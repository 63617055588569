import { TRANSFER } from '../../booking/models/transfer';
import { SERVICE_TYPE_ID } from '../../booking/models/service-type';

// gate service is disabled if:
// serviceType is Airport-Shuttle AND it is pick up from MUC airport AND requested pickup time is between 10pm and 1am

const isGateServiceDisabled = (transfer, secondTransfer) => {
    const transferFromMunich = [transfer, secondTransfer]
        .filter((item) => !!item)
        .find((item) => item.typeId === TRANSFER.FROM_MUNICH);

    if (!transferFromMunich) {
        return false;
    }

    const isServiceCollective = transferFromMunich.serviceTypeId === SERVICE_TYPE_ID.COLLECTIVE;

    if (!isServiceCollective) {
        return false;
    }

    const transferHour = Number(transferFromMunich.flight.time.split(':')[0]);

    return transferHour >= 22 || transferHour < 1;
};

export { isGateServiceDisabled };
