import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { findLang } from '../../../common/models/lang';

const Terms = () => {
    const { t, i18n } = useTranslation();

    const links = {
        de: {
            terms: 'https://www.airportliner.com/agb/',
            policy: 'https://www.airportliner.com/datenschutzerklaerung/',
        },
        en: {
            terms: 'https://www.airportliner.com/en/terms-of-conditions/',
            policy: 'https://www.airportliner.com/en/privacy/',
        },
    };

    return (
        <Stack alignItems="center">
            <Typography variant="caption" component="div" sx={{ maxWidth: '600px', textAlign: 'center' }}>
                {t('booking.acceptTerms1')}{' '}
                <Typography
                    variant="caption"
                    component="a"
                    color="primary.main"
                    href={links[findLang(i18n.language).i18nCode].terms}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('booking.acceptTerms2')}
                </Typography>{' '}
                {t('booking.acceptTerms3')}{' '}
                <Typography
                    variant="caption"
                    component="a"
                    color="primary.main"
                    href={links[findLang(i18n.language).i18nCode].policy}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {t('booking.acceptTerms4')}{' '}
                </Typography>
                {t('booking.acceptTerms5')}
            </Typography>
        </Stack>
    );
};

export { Terms };
