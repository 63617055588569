function listItemLabel(item) {
    if (item.type) {
        return [item.name, item.place, item.street].join(', ');
    }
    return item.zip
        ? `${item.zip},${item.place ? ' ' : ''}${item.place}${item.street ? ', ' + item.street : ''}`
        : item.place;
}

export { listItemLabel };
